import React from "react";
import Slider from "react-slick";

import bannerImg1 from "./images/banner-img.jpg";
import bannerImg2 from "./images/banner-img-bkp-2.jpg";
import bannerImg3 from "./images/banner-img-bkp-3.jpg";
import bannerImg4 from "./images/banner-img-bkp-4.jpg";
import { Link } from "react-router-dom";

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  fade: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 500,
  cssEase: "linear",
};

const slides = [
  {
    img: bannerImg1,
  },
  {
    img: bannerImg2,
  },
  {
    img: bannerImg3,
  },
  {
    img: bannerImg4,
  },
];

const Banner = ({ index, img, ...props }) => {
  return (
    <div className="bannerMain">
      <div className="bannerImage bgCover custom" style={{ backgroundImage: `url(${img})` }}></div>
      <div className="bannerInner">
        <div className="container">
          <div className="row">
            <div className="col-lg-100 col-md-67 bannerContentPart">
              <div className="bannerContent">
                <div className="bannerTitle">
                  <div className="borderColors small">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <h1>
                    <span className="fullWidth">For The</span>
                    <span className="fullWidth">Fiercely</span>
                    <span className="fullWidth">Independent</span>
                  </h1>
                </div>
                <p className="m-0">Good music knows no bounds. Let the world hear your sound today on UNIFI Music.</p>
                <p>AI-Powered, Artist-Driven.</p>
                <div className="buttonOuter">
                  <Link className="commonButton shine commonButton--uppercase commonButton--wide" to="/signup">
                    <span>START FREE 30-DAY TRIAL</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BannerSlider = () => {
  return (
    <div>
      <Slider {...settings}>
        {slides.map((item, index) => (
          <Banner img={item.img} key={index} />
        ))}
      </Slider>
    </div>
  );
};

export default BannerSlider;
