import React from "react";
import { Link } from "react-router-dom";
import ArtistSlider from "./ArtistSlider";

const FreeMusicPromotionMain = () => {
  return (
    <div className="freeMusicPromotionMain blackBg commonPT">
      <div className="freeMusicPromotionInner">
        <div className="subHeading">
          <h3>
            Free Music <span>promotion</span>
          </h3>
        </div>
        <ArtistSlider />
        <div className="subHeading text-right">
          <h3>
            For Independent <span>artists</span>
          </h3>
        </div>
      </div>
      <div className="videoOuter commonPT">
        <div className="container">
          <div className="join-our-growing-div">
            <p>
              Join our growing global music community today to connect, collab, and create with independent artists, bands, producers, MCs,
              singer/songwriter, DJs, podcasters, dancers, and anyone else involved with bringing the music we all love to life. Get started today and
              take your music journey to the next level.
            </p>
            <div className="buttonOuter">
              <Link to="/signup" className="commonButton shine commonButton--pink commonButton--uppercase commonButton--wide">
                <span>START FREE 30-DAY TRIAL</span>
              </Link>
            </div>
            {/* <div className="videoPart">
              <iframe
                src="https://www.youtube.com/embed/SZlgcgSjaK8"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeMusicPromotionMain;
