import React, { useState, useEffect } from "react";
import luckyMacbookImg from "./images/lucky-macbook-img1.png";
import aNatualMockup from "./images/a-natual-mockup1.png";
import futureSoundMockup from "./images/future-sound-mockup1.png";
import mobileImg1 from "./images/mobile-img1.png";
import mobileImg2 from "./images/mobile-img2.png";

const CommonContentMain = () => {
  //Swipe Gallery Images

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => {
        var $active = document.querySelector(".slideImages .active");
        var $next = !!document.querySelector(".slideImages .active").nextElementSibling
          ? document.querySelector(".slideImages .active").nextElementSibling
          : document.querySelector(".slideImages img");
        $active.classList.remove("active");
        $next.classList.add("active");
      }, 2000);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="commonContentMain">
      <div className="container">
        <div className="commonContentOuter">
          <div className="commonContentInner">
            <div className="row">
              <div className="col-md-5 contentGrid">
                <div className="contentImg doubleImg">
                  <img className="mobileImg one wow fadeIn" data-wow-delay="00ms" data-wow-duration="800ms" src={mobileImg1} alt="" />
                  <img className="mobileImg two wow fadeIn" data-wow-delay="200ms" data-wow-duration="800ms" src={mobileImg2} alt="" />
                </div>
              </div>
              <div className="col-md-7 contentGrid">
                <div className="contentContent">
                  <h4>
                    <span className="text-uppercase mob-block d-block">Let's talk music</span>
                  </h4>
                  <p>
                    With our <span className="text-uppercase pinkColor">Tipjar</span> feature, get tips via your UNIFI Music profile and keep 100%
                    (Yep, no fees or services charges!)
                  </p>
                  <p>
                    Let your adoring fans show you how awesome they think your music is with our <span className="pinkColor">Tipjar</span> feature
                    (included with a PRIMO subscription).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="commonContentInner">
            <div className="row">
              <div className="col-md-5 contentGrid" style={{ transform: `scale(0.84)` }}>
                <div className="">
                  <div className="contentImg slideImages laptopFrame">
                    <img className="luckyMacbookImg active" src={luckyMacbookImg} alt="" />
                    <img className="luckyMacbookImg" src={aNatualMockup} alt="" />
                    <img className="luckyMacbookImg" src={futureSoundMockup} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-7 contentGrid">
                <div className="contentContent">
                  <h4>
                    Promote your music and playlists how you want, whenever you want, with <span className="pinkColor mobText-black">Embeddable</span>{" "}
                    Music Players
                  </h4>
                  <p>
                    Embed our shareable, viral-ready audio player with your game-changing music and your own playlists into your website, in your
                    interview articles, music blogs, and wherever else you want!
                  </p>
                  <p>
                    Build marketing awareness of your new releases, introduce fans to your music faster, and bring them back to your{" "}
                    <span className="pinkColor">UNIFI Music</span> profile to build your audience for the long haul!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonContentMain;
