import { ADD_CHATS } from "../actions";

export default (state = null, action) => {
  const newstate = { ...state };
  if (!newstate.chats) {
    newstate.chats = [];
  }

  switch (action.type) {
    case ADD_CHATS:
      return { ...newstate, chats: [...newstate.chats, action.chat] };
  }
  return newstate;
};
