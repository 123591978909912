import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import EpkBanner from "./images/epk-banner.jpg";
import LandscapePromo from "./videos/landscape-promo-hm.mp4";

const MobileEpk = () => {
  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("vid")) {
        document.getElementById("vid").play();
      }
    }, 1000);
    return () => {};
  }, []);

  return (
    <div className="mobileEpk commonPT blackBg text-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 col-lg-11">
            <h3 className="mdTitle yellowColor">
              Free MOBILE ELECTRONIC PRESS KIT (EPK)
              <br />
              INCLUDED WHEN YOU JOIN
            </h3>
            <p>Share your story, feature your music, connect with like-minded creatives, and present your music one hundred to booking agents.</p>
          </div>
          <div className="col-12 col-md-12 col-lg-11">
            <div className="mobileEpkBanner">
              <video poster={EpkBanner} muted autoPlay playsInline="" loop={true} preload="none" id="vid">
                <source src={LandscapePromo} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-11">
            <h3 className="mdTitle yellowColor">Unlimited music distribution</h3>
            <div className="mobileEpkContent">
              <p>Distribute as much music as you want and keep 100% of your streaming royalties and sales revenues.</p>
              <p>
                ...Creatives. Thanks to our intelligent technology, your Mobile EPK evolves naturally as you use your profile and interact with the
                UNIFI Music community.
              </p>
              <p>It’s great for your social media bio link, and now you can forget about having to update boxes and links manually all the time!</p>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-11">
            <div className="primoPlanOuter">
              <h3 className="mdTitle whiteColor">OUR PRIMO PLAN</h3>
              <div className="primoPlanInner">
                <div className="primoPlanContent">
                  <h4 className="linkColor mb-3">What's Included:</h4>
                  <h4 className="linkColor">Keep 100% of Your Streaming Royalties and Sales.</h4>
                  <p>All payments are made directly to you via your PayPal address.</p>
                  <h4 className="linkColor">Sasha, your AI Music Manager</h4>
                  <p>
                    Tired of trying to find a manager? Look no further, UNIFI Music has you covered with Sasha, your AI Music Manager to help you
                    write social posts, figure out the best places to tour, and more.
                  </p>
                  <h4 className="linkColor">Tipjar.</h4>
                  <p>Get tips from anywhere via your UNIFI music profile. You keep 100% of what you receive.</p>
                  <h4 className="linkColor">Unlimited Music Distribution.</h4>
                  <p>Distribute as much music as you want, straight from your UNIFI music account.</p>
                  <h4 className="linkColor">No commitments.</h4>
                  <p>Cancel anytime. Yep, it's that simple!</p>
                </div>
              </div>
              <div className="primoPlanBottom">
                <div className="priceMonth whiteColor">$40 / Month</div>
                <div className="buttonOuter mt-2">
                  <Link className="commonButton shine commonButton--black commonButton--uppercase commonButton--wide" to="/signup">
                    <span>START FREE 30-DAY TRIAL</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileEpk;
