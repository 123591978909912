import React, { useState, useEffect } from "react";

import luckyMacbookImg from "./images/lucky-macbook-img.png";
import aNatualMockup from "./images/a-natual-mockup.png";
import futureSoundMockup from "./images/future-sound-mockup.png";
import mobileImg1 from "./images/mobile-img1.png";
import mobileImg2 from "./images/mobile-img2.png";

let count = 0;
const macBookImages = [luckyMacbookImg, aNatualMockup, futureSoundMockup];

const PromotionBlock = () => {
  const [selectedImage, setSelectedImage] = useState(macBookImages[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => {
        count = count === macBookImages.length - 1 ? 0 : count + 1;
        setSelectedImage(macBookImages[count]);
      }, 2000);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="commonContentMain commonPB">
      <div className="container">
        <div className="commonContentOuter">
          <div className="commonContentInner">
            <div className="row">
              <div className="col-md-41 contentGrid">
                <div className="contentImg slideImages">
                  <img className={`luckyMacbookImg ${selectedImage === luckyMacbookImg ? "active" : ""}`} src={luckyMacbookImg} alt="" />
                  <img className={`luckyMacbookImg ${selectedImage === aNatualMockup ? "active" : ""}`} src={aNatualMockup} alt="" />
                  <img className={`luckyMacbookImg ${selectedImage === futureSoundMockup ? "active" : ""}`} src={futureSoundMockup} alt="" />
                </div>
              </div>
              <div className="col-md-59 contentGrid">
                <div className="contentContent">
                  <h4>
                    Promote your music how you want, wherever you want, with <span>Embeddable Music Players</span>
                  </h4>
                  <p>
                    Integrating a music player with your game-changing music from UNIFI Music into your websites, blogs, magazine interviews, and
                    feature promotions is easy. And the best part is, it's a FREE music promotion tool you can use, and works with all albums, tracks,
                    playlists, and even podcasts that you add to your UNIFI Music profile.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="commonContentInner">
            <div className="row">
              <div className="col-md-41 contentGrid">
                <div className="contentImg doubleImg">
                  <img className="mobileImg one" src={mobileImg1} alt="" />
                  <img className="mobileImg two" src={mobileImg2} alt="" />
                </div>
              </div>
              <div className="col-md-59 contentGrid">
                <div className="contentContent">
                  <h4>
                    Let your adoring fans show you how awesome they think your music is with our <span>TipJar</span> feature (included with a PRIMO
                    subscription).
                  </h4>
                  <p>
                    Think of it as an open instrument case on a busy city street, chock full of stray bills and coins signaling just how talented
                    people think you are.
                  </p>
                  <p style={{ fontWeight: "bold" }}>After all, money talks louder than words.</p>
                  <p>
                    Activate your TipJar for just $1/month and start accepting those dollars. And don't worry, no heartless vagabond is gonna swipe
                    your earnings and run off. With UNIFI Music, there are no service charges, percentages taken, or hidden fees ever.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionBlock;
