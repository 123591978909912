import axios from "axios";
import { version } from "./../version.json";

let Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    BUILDNUMBER: version,
    OS: "web",
  },
  responseType: "json",
  contentType: "json",
});
Axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getAuthorizationToken();
    return config;
  },
  (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
  (response) => {
    if (response.data && response.data.guest_token) sessionStorage.setItem("Authorization", response.data.guest_token);
    if (response.data && response.data.user_token) {
      if (response.data.remember_me) {
        localStorage.setItem("Authorization", response.data.user_token);
        localStorage.setItem("sb_token", response.data.sb_token);
      } else {
        sessionStorage.setItem("Authorization", response.data.user_token);
        sessionStorage.setItem("sb_token", response.data.sb_token);
      }
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      if (error.response.config.url.indexOf(`/oauth/authorize`) < 0) {
        sessionStorage.clear();
        localStorage.clear();
        onUnauthorizedActionAttempt();
      }
    }
    log_error(error);
    return Promise.reject(error?.response?.data?.errors || error?.message || error);
  }
);

axios.get("https://ipapi.co/json").then(({ data }) => {
  Axios.defaults.headers["City"] = data.city;
  Axios.defaults.headers["State"] = data.region;
  Axios.defaults.headers["Country"] = data.country_code_iso3;
});

export default Axios;

export const log_error = (e) => {
  if (e.response && e.response.data) {
    const response = e.response.data;
    if (response.errors && Array.isArray(response.errors)) {
      console.log("=====errors", response.errors);
      console.warn(`${e.response.status}:${e.response.statusText} ${JSON.stringify(response.errors)}`);
      return response.errors;
    }
    if (e.message) {
      return [{ message: e.message }];
    }
  } else {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.base || e.twitter) {
      return (e.base || e.twitter).map((message) => ({ message }));
    }
    console.warn("HV_ERRORS :: ", e.message);
    return [{ message: "Unknown Error" }];
  }
};

let callbacks = [];

export function addUnauthorizedCallback(callback) {
  callbacks.push(callback);
}

export function onUnauthorizedActionAttempt() {
  callbacks.forEach((callback) => {
    callback();
  });
}

export function getAuthorizationToken() {
  return localStorage.getItem("Authorization") || sessionStorage.getItem("Authorization");
}
